var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0 border-radius-16 transparent-bg",class:{
    'pa-2': _vm.smallPadding,
    'pa-5': !_vm.smallPadding,
    'card-hover cursor-pointer': _vm.openDetail,
    'card-border': _vm.cardBorder,
  },on:{"click":_vm.openCharacterDetail}},[_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"border-radius-16",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"avatar-frame full-width full-height",class:{
              'border-radius-16 big-gray-border': _vm.character.Grade !== 1,
            },style:(("max-width: " + _vm.maxWidth + "px; max-height: " + _vm.maxWidth + "px"))},[(_vm.character.Grade === 1)?_c('v-img',{attrs:{"src":require('@/assets/big-rare-frame.png'),"contain":""}}):_vm._e()],1),_c('CharacterAvatar',{style:(("max-width: " + _vm.maxWidth + "px; max-height: " + _vm.maxWidth + "px")),attrs:{"avatar":_vm.character.AvatarURL,"race":_vm.character.Race,"hide-video":true}})],1),_c('div',{staticClass:"flex-grow-1 ml-8"},[_c('CharacterIndex',{attrs:{"index":_vm.character.ID,"element":_vm.character.Class}}),_c('CharacterAttribute',{attrs:{"character":_vm.character,"smallGap":true}})],1)])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"5"}},[_c('CharacterStat',{attrs:{"character":_vm.character,"colSize":6,"small":true}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }